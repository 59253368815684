




























































  import { INSURANCES, PRODUCTS } from '@/constant/Enums';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import moment from 'moment';
  import { Prop } from 'vue-property-decorator';
  import { Action, Mutation, State } from 'vuex-class';
  import { ServerResponse } from '@/services/response.types';
  import { FormCompareMotorModel, ProductMotorState } from '@/store/product/motor/types';
  import { PlateModel } from '@/store/product/types';

  @Component({
    name: 'compareMotor',
    components: {},
    mixins: [],
  })
  export default class compareMotor extends Vue {

    @Prop({ default: null }) title!: string;
    @Prop({ default: null }) desciption!: string;
    @Prop({ default: 'BANDINGKAN SEKARANG' }) buttonTitle!: string;

    @Action('GetMotor', { namespace: 'product/motor' }) GetMotor!: (params?: any) => Promise<ServerResponse>;
    @Action('GetPlates', { namespace: 'product/motor' }) GetPlates!: (params?: any) => Promise<ServerResponse>;
    @Action('CompareAction', { namespace: 'product/motor' }) CompareAction!: (params?: any) => Promise<ServerResponse>;

    @State((state: ProductMotorState) => state.title.HTMLtoString(), { namespace: 'product/motor' }) _title !: string;
    @State((state: ProductMotorState) => state.content.HTMLtoString(), { namespace: 'product/motor' }) content !: string;
    @State('plates', { namespace: 'product/motor' }) plates!: PlateModel[];
    @State('form', { namespace: 'product/motor' }) formMotor!: FormCompareMotorModel;
    @State('onLoad', { namespace: 'product/motor' }) onLoad!: boolean;

    @Mutation('set-form', { namespace: 'product/motor' }) SetForm!: (form: any) => void;

    private routeName = 'compare-' + PRODUCTS[0].name.toLowerCase().replace(' ', '-');

    public get titleMutator() : string {
      return this.title??this._title
    }

    public get descriptionMutator() : string {
      return this.desciption??this.content
    }

    public get optionPlateNumber(): { text: string; value: any }[] {
      return this.plates.map((v) => Object.assign({}, { text: `${v.plate_number_code} ${v.plate_number_city}`, value: v.plate_number_id }));
    }

    public get form(): FormCompareMotorModel {
      return this.formMotor;
    }

    public set form(v: FormCompareMotorModel) {
      this.SetForm(v);
    }

    // public optionPlateNumber = PLATE_NUMBERS;

    public get optionTypeInsurances() : {text: string; value: number; selected?:boolean }[] {
      return INSURANCES.filter((item) => item.value !== 2)
    }

    public get optionsYear(): number[] {
      const years = [];
      const currentYear = moment().get('year');
      for (let i = 0; i < 20; i++) {
        years.push(currentYear - i);
      }
      return years;
    }

    public created(): void {
      this.GetMotor()
      this.GetPlates().then((res) => {
        let history = localStorage.getItem('motor-history');
        if (typeof history === 'string') {
          this.form = JSON.parse(history);
          if(this.optionPlateNumber.length === 1) this.form.coverage = this.optionTypeInsurances[0].value
          if (this.$route.name === this.routeName) this.CompareAction();
        }
      });
    }
    public mounted(): void {

    }

    /**
     * OnSubmit
     */
    public OnSubmit() {
      localStorage.setItem('motor-history', JSON.stringify(this.form));

      this.CompareAction();
      this.$route.name !== this.routeName ? this.$router.push({ name: this.routeName }) : 1;
    }
  }
